import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Wizard } from '@shared/ui/misc/Wizard/Wizard';
import SelectExchange from './steps/SelectExchange';
import SelectWallet from './steps/SelectWallet';
import ConnectExchange from './steps/ConnectExchange';
import Success from './steps/Success';
import Error from './steps/Error';
import SuccessReport from './steps/SuccessReport';
const ConnectExchangeSidebar = () => (_jsxs(Wizard, { children: [_jsx(SelectExchange, {}), _jsx(ConnectExchange, {}), _jsx(SelectWallet, {}), _jsx(Success, {}), _jsx(Error, {}), _jsx(SuccessReport, {})] }));
export default ConnectExchangeSidebar;
