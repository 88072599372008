import styled from '@emotion/styled';
import { ReactComponent as BinanceIconComponent } from '@icons/exchanges/binance-name.svg';
import { ReactComponent as CommexIconComponent } from '@icons/exchanges/commex-name.svg';
import { ReactComponent as BitmexIconComponent } from '@icons/exchanges/bitmex-name.svg';
import { ReactComponent as CoinsferaIconComponent } from '@icons/exchanges/coinsfera-name.svg';
const CommexIcon = styled(CommexIconComponent)(() => ({
    width: 92,
    height: 24,
    rect: {
        opacity: 1,
    },
}));
const BitmexIcon = styled(BitmexIconComponent)(() => ({
    width: 88,
    height: 24,
}));
const CoinsferaIcon = styled(CoinsferaIconComponent)(() => ({
    width: 108,
    height: 24,
}));
const BinanceIcon = styled(BinanceIconComponent)(() => ({
    height: 24,
    width: 120,
}));
const IconContainer = styled.div(() => ({
    height: 24,
    textAlign: 'center',
}));
export { BinanceIcon, CoinsferaIcon, BitmexIcon, CommexIcon, IconContainer, };
