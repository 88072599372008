var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch } from 'react-redux';
import { useWizard } from '@shared/ui/misc/Wizard';
import { closeSidebar, } from '@store/slices/exchange';
import { useAppSelector } from '@app/store/Hooks';
import { useCreateExchangeAccountMutation } from '@shared/api/exchange';
import { ConnectBinanceSteps } from './constants';
import mapFieldsForCreateReq from './utils';
const useBinanceConnect = () => {
    const { current, goTo } = useWizard();
    const dispatch = useDispatch();
    const { sidebarFormValues } = useAppSelector(state => state.exchange);
    const [createReq, { isLoading: isCreating }] = useCreateExchangeAccountMutation();
    const handleClose = () => {
        dispatch(closeSidebar());
    };
    const handleExchangeCreate = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const mappedFields = mapFieldsForCreateReq(values);
        const res = yield createReq(mappedFields);
        if (res === null || res === void 0 ? void 0 : res.data) {
            goTo(ConnectBinanceSteps.Success);
            return true;
        }
        return { error: res === null || res === void 0 ? void 0 : res.error };
    });
    const goToNextStep = () => {
        switch (current) {
            case 1:
                handleClose();
                break;
            case 2:
                handleClose();
                break;
            case 0:
            default:
                break;
        }
    };
    return {
        goToNextStep,
        handleClose,
        sidebarFormValues,
        handleExchangeCreate,
        isCreating,
    };
};
export default useBinanceConnect;
